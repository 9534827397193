import React, { useState } from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { loginRequest } from './authConfig';
import { getCalendarEvents } from './graph';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-bootstrap/Modal';


const localizer = momentLocalizer(moment);


const CalendarEventsContent = () => {
    const { instance, accounts } = useMsal();
    const [calendarEvents, setCalendarEvents] = useState(null);
    const transformedEvents = calendarEvents ? transformEvents(calendarEvents) : [];
    const [textareaContent, setTextareaContent] = useState('私の都合の良い日時は下記です。')

    const handleTextareaChange = (e) => {
        setTextareaContent(e.target.value);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textareaContent).then(
            () => {
                alert('クリップボードにコピーしました！');
            },
            (err) => {
                console.error('クリップボードへのコピーに失敗しました…:', err);
            }
        );
    };

    const handleRangeSelect = (slotInfo) => {
        const formatDateTime = (date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = String(date.getHours()).padStart(2, '0');
            const minute = String(date.getMinutes()).padStart(2, '0');
            const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
            return `${year}/${month}/${day}(${weekday}) ${hour}:${minute}`;
        };

        const start = formatDateTime(slotInfo.start);
        const end = formatDateTime(slotInfo.end);
        const newRangeText = `${start} - ${end}`;

        setTextareaContent((prevContent) =>
            prevContent === '' ? newRangeText : prevContent + '\n' + newRangeText

        );
    };



    function RequestCalendarEvents() {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                getCalendarEvents(response.accessToken).then((response) => setCalendarEvents(response.value));
            });
    }

    return (
        <>
            <h5 className="card-title"> {accounts[0].name}としてサインインしました</h5>
            {calendarEvents ? (
                <>
                    <Calendar
                        localizer={localizer}
                        events={transformedEvents}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="week"
                        style={{ height: 500 }}
                        selectable
                        onSelectSlot={handleRangeSelect}
                        scrollToTime={new Date(1970, 1, 1, 8)}
                    />
                    <textarea
                        value={textareaContent}
                        onChange={handleTextareaChange} // テキストボックスの変更を処理するイベントハンドラを追加
                        style={{ width: '50%', height: '200px', marginTop: '20px' }}
                    />
                    <br />
                    <Button
                        variant="primary"
                        onClick={copyToClipboard}
                        style={{ marginTop: '10px' }}
                    >
                        クリップボードにコピー
                    </Button>
                </>
            ) : (
                <Button variant="secondary" onClick={RequestCalendarEvents}>
                    予定表情報の取得！
                </Button>
            )}


        </>
    );
};

function transformEvents(events) {
    return events.map((event) => {
        // イベントの開始日時と終了日時をDateオブジェクトに変換
        const start = new Date(event.start.dateTime);
        const end = new Date(event.end.dateTime);

        // UTCからJSTに変換
        const offsetInMs = 9 * 60 * 60 * 1000; // 9時間のオフセット (ミリ秒)
        const startInJST = new Date(start.getTime() + offsetInMs);
        const endInJST = new Date(end.getTime() + offsetInMs);

        return {
            title: event.subject,
            start: startInJST,
            end: endInJST,
        };
    });
}



/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    const [showModal, setShowModal] = useState(false);


    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    return (
        <div className="App">
            <Button variant="info" onClick={handleModalShow} className="help-btn">
                ヘルプ
            </Button>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>アプリケーションの説明</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        このアプリケーションはカレンダーを範囲選択するとその日時をテキストボックスに追加し、クリップボードにコピーすることができます。
                    </p>
                    <p>
                        Microsoft Graph APIを用いて予定情報を取得、表示しますので、空いている時間を簡単に選択し、相手に伝えることができます。
                    </p>
                    <p>
                        テキストエリアは自由に編集できますので自由にお使いください！
                    </p>
                    <h4>問い合わせ先</h4>
                    <p>
                        バグ報告や機能追加要望等は下記のTwitterアカウントまでお寄せください!<br />
                        <a href="https://twitter.com/ebi">@ebi</a>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        閉じる
                    </Button>
                </Modal.Footer>
            </Modal>
            <AuthenticatedTemplate>
                <CalendarEventsContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">利用するためには、まずサインインをお願いします！</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
